@import '../../../../../../static/scss/global/variables';

.activeImageContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  overflow: hidden;
  transition: all 0.3s;
}

.activeImageWrapper {
  position: relative;
}

.activeImage {
  height: 402px;
  object-fit: cover;
  @include for-big-phone-only {
    width: 288px;
    height: auto;
    max-height: 174px;
  }
}

.wrapperArrow {
  position: absolute;
  z-index: 2;
  top: calc(50% - 20px);
  opacity: 1;
  border-radius: 0 4px 4px 0;
  border-width: 3px 3px 3px 0;
  border-style: solid;
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.3);

  &:hover {
    border-color: #ffffff;
  }
}

.wrapperRightArrow {
  right: 0;
}

.arrow {
  fill: #ffffff;
}
