@import '../../../static/scss/global/variables';

.wrapperComponent {
  display: grid;
  grid-auto-flow: column;
  align-content: center;
  margin-left: 10px;
  @include for-big-phone-only {
    font-size: 14px;
  }
}

.signInButton {
  color: $white100fontColor;
  cursor: pointer;
}

.signUpButton {
  color: $themeColor;
  padding-left: 20px;
  cursor: pointer;
}
