@import '../../../static/scss/global/variables';

.section {
  min-height: 200px;
  background: $lightSectionColor;
  padding: 56px 0;
  @include change-zoom {
    padding: 30px 0;
  }

  & .sectionWrapper {
    margin: 0 auto;
    padding: 0 15px;
  }
}

.button {
  text-align: center;
  margin-top: 40px;

  @include change-zoom {
    margin-top: 30px;
  }
}

.carouselWrapper {
  max-width: 1230px;
  margin: 0 auto;
  @include not-a-desktop {
    margin: 0 15px;
  }
}

.controlArrow {
  opacity: 0.3;
  width: 24px;
  height: 37px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='37' viewBox='0 0 24 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.334 18.666L4.8 37L0 32.246L13.726 18.666L0 5.086L4.8 0.332001L23.334 18.666Z' fill='black'/%3E%3C/svg%3E%0A");
  transition: opacity 0.15s ease-in;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.controlPrev {
  transform: rotate(180deg);
}

.disabled {
  display: none;
}

.button {
  text-align: center;
  margin-top: 40px;

  @include change-zoom {
    margin-top: 20px;
  }
}
