@import '../../../static/scss/global/variables';

.social {
  .link {
    margin-left: 20px;
    margin-top: 20px;
    width: 20px;
    height: 20px;
    &:first-child {
      margin-left: 0;
    }
    svg use {
      fill: $white100fontColor;
    }

    &:hover {
      svg use {
        fill: $themeColor;
      }
    }
  }
}
