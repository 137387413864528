@import '../../../static/scss/global/variables';

.item {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background: $lightSectionColor;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
}

.active {
  background: $darkSectionColor;
  color: $white100fontColor;
}

.disabled {
  cursor: default;
}
