$grey-color: #999;
$text-color: #333;

// viewports

$smallPhoneWidth: 320px;
$phoneWidth: 480px;
$tableWidth: 768px;
$netbookWidth: 980px;
$laptopWidth: 1280px;
$desktopWidth: 1800px;
$desktopHeight: 992px;

@mixin for-phone-only {
  @media (max-width: 320px) {
    @content;
  }
}
@mixin for-blog-phone {
  @media (max-width: 399px) {
    @content;
  }
}
@mixin for-big-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin not-a-phone {
  @media (min-width: 321px) and (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-only {
  @media (max-width: 899px) {
    @content;
  }
}
@mixin for-tablet-up {
  @media (min-width: 600px) and (max-width: 899px) {
    @content;
  }
}

@mixin not-a-desktop {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 900px) and (max-width: 1199px) {
    @content;
  }
}

@mixin for-desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin change-zoom {
  @media (max-width: 1399px) {
    @content;
  }
}

@mixin change-zoomLarge {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin print-style {
  @media print {
    @content;
  }
}

// containers

$container-max-widths: 1170px;

// fonts
$mainFont: 'Roboto', Helvetica, Arial, sans-serif;
$secondaryFont: 'Open Sans', Helvetica, Arial, sans-serif;
$headerFont: 'Poppins', Helvetica, Arial, sans-serif;
$buttonFont: $headerFont;

// colors

$themeColor: #eca52c;
$lightSectionColor: #f5f5f5;
$lightHoverSectionColor: #fbfbfb;
$darkSectionColor: #2a323c;
$darkSectionColorOp080: #555b63;
$darkSectionColorOp060: #7e8288;
$darkSectionColorOp040: #aaadb1;
$darkestSectionColor: #0f1318;
$white100fontColor: #ffffff;

$errorColor: #d5322a;

$mainFontColor: $darkSectionColor;
$mainFontColorOp080: #555b63;
$white080fontColor: #d4d6d8;

$borderBaseColor: #ededed;
$borderFocusColor: $themeColor;

// spacers

$spacers: (
  'm50': -50px,
  'm45': -45px,
  'm40': -40px,
  'm35': -35px,
  'm30': -30px,
  'm25': -25px,
  'm20': -20px,
  'm15': -15px,
  'm10': -10px,
  'm5': -5px,
  5: 5px,
  10: 10px,
  15: 15px,
  20: 20px,
  25: 25px,
  30: 30px,
  35: 35px,
  40: 40px,
  45: 45px,
  50: 50px,
  55: 55px,
  60: 60px,
  65: 65px,
  70: 70px,
  75: 75px,
  80: 80px,
  85: 85px,
  90: 90px,
  100: 100px,
  105: 105px,
  110: 110px,
  115: 115px,
  120: 120px,
  125: 125px,
  130: 130px,
  135: 135px,
  140: 140px,
  145: 145px,
  150: 150px,
);

// z-index

$zIndexHeader: 20;
