@import '../../../../../../static/scss/global/variables';

.itemWrapper {
  @include not-a-desktop {
    display: none;
  }
}

.item {
  width: 24px;
  height: 24px;
  margin-left: 20px;
  cursor: pointer;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  fill: $darkSectionColorOp040;
  transition: opacity 0.3s ease-out;

  &:hover {
    fill: $themeColor;
    opacity: 0.4;
  }

  &.active {
    fill: $themeColor;
  }
}
