@import '../../../../../../static/scss/global/variables';

.stringName {
  flex: 0 0 auto;
  margin-right: 10px;
  color: $darkSectionColorOp060;
  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }
}
