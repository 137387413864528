@import '../../../../static/scss/global/variables';

.wrapperComponent {
  display: flex;
  height: 170px;
  width: 170px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: $white100fontColor;
  border-radius: 16px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
  margin: 0 auto 16px;

  //@include change-zoom {
  //  height: 136px;
  //  width:  136px;
  //}

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.32);
    .image {
      -webkit-filter: none;
      filter: none;
    }
  }

  .image {
    object-fit: cover;
    max-width: 140px;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);

    //@include change-zoom {
    //  max-width: 110px;
    //}
  }
}
