@import '../../../static/scss/global/variables';

.popup {
  min-width: 400px;
  @media (max-width: 450px) {
    min-width: 200px;
  }
}

.dateBlock {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 450px) {
    flex-direction: column;
  }

  .dataPicker {
    min-width: 170px;
    @media (max-width: 450px) {
      width: 100%;
    }
  }

  .blockTime {
    display: flex;
    align-items: center;

    @media (max-width: 450px) {
      width: 100%;
      padding-top: 16px;
    }

    span {
      font-family: $mainFont;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $darkSectionColor;
      opacity: 0.4;
    }
    .hour {
      div {
        width: 60px;
        min-width: auto;
        max-width: 60px;
      }
    }
  }
}

.tab {
  min-height: 32px;
  width: 150px;

  @media (max-width: 450px) {
    flex-direction: column;
    width: 50%;
  }
}
