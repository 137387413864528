@import '../../../static/scss/global/variables';

.wrapperComponent {
  min-height: 200px;
  padding: 56px 0;
  background-color: $lightSectionColor;

  @include change-zoom {
    padding: 30px 0;
  }
}

.newsList {
  display: grid;
  grid-template-columns: repeat(auto-fit, 270px);
  grid-gap: 30px;
  justify-content: center;
  margin-bottom: 40px;

  @include change-zoom {
    margin-bottom: 30px;
    grid-template-columns: repeat(auto-fit, 216px);
    grid-gap: 20px;
  }

  @include not-a-desktop {
    grid-template-columns: repeat(2, 270px);
  }

  @include for-big-phone-only {
    grid-template-columns: repeat(auto-fit, 270px);
  }
}

.goToBlogLink {
  display: block;
  width: 170px;
  height: 38px;
  margin: auto;
  text-align: center;
  text-transform: uppercase;
  font-family: $buttonFont;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  color: $white100fontColor;
  background-color: $themeColor;

  span {
    display: inline-block;
    padding-top: 12px;
  }

  &:hover {
    background-color: #ffb230;
  }

  @include for-big-phone-only {
    min-width: 288px;
  }
}

.sectionHeader {
  margin-bottom: 35px;

  @include change-zoom {
    margin-bottom: 20px;
  }
}
