@import '../../../static/scss/global/variables';

.section {
  min-height: 200px;
  background: $darkSectionColor;
  padding: 56px 0;
  @include change-zoom {
    padding: 30px 0;
  }

  & .sectionWrapper {
    margin: 0 auto;
  }
}

.carouselWrapper {
  margin: 0 15px;

  @media (max-width: 400px) {
    margin: 0;
  }
}

.controlArrow {
  opacity: 0.5;
  color: #fff;
  width: 24px;
  height: 37px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='37' viewBox='0 0 24 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.334 18.666L4.8 37L0 32.246L13.726 18.666L0 5.086L4.8 0.332001L23.334 18.666Z' fill='white'/%3E%3C/svg%3E%0A");
  transition: opacity 0.15s ease-in;
  margin-top: 95px;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.controlPrev {
  transform: rotate(180deg);
}

.buttonWrapper,
.buttonPrevWrapper {
  background: linear-gradient(270deg, #2a323c, rgba(42, 50, 60, 0));
  width: 110px;
  height: 225px;
  opacity: 1;
  position: absolute;
  left: -68px;

  &.disabled {
    display: none;
  }
}

.buttonPrevWrapper {
  background: linear-gradient(270deg, rgba(42, 50, 60, 0), #2a323c);
  left: 11px;
}

.controlNext {
  margin-left: 80px;
}

.controlDots {
  margin-top: 50px;
  display: flex;
  justify-content: center;

  @include change-zoom {
    margin-top: 30px;
  }

  .dot {
    width: 10px;
    height: 10px;
    box-shadow: none;
    background: rgba(#ffffff, 0.4);
    border-radius: 50%;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  .active {
    background: #eca52c;
    opacity: 1;
  }
}

.sectionHeaderDynamic {
  margin-bottom: 35px;

  @include change-zoom {
    margin-bottom: 20px;
  }
}
