@import '../../../static/scss/global/variables';

.wrapperSection {
  position: relative;
  &:hover {
    .img {
      opacity: 1;
    }
  }
}

.img {
  width: 32px;
  height: 32px;
  opacity: 0.6;
  use {
    fill: white;
  }
}

.hasItems {
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: $themeColor;
    border: 1px solid $darkSectionColor;
    border-radius: 50%;
    top: 2px;
    right: 2px;
  }
}

.updateLength {
  svg {
    use {
      animation: blinking 3s 1;
    }
  }
}

@keyframes blinking {
  0% {
    fill: rgba(white, 0.6);
  }
  25% {
    fill: $themeColor;
  }
  100% {
    fill: rgba(white, 0.6);
  }
}
