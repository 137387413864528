@import '../../../static/scss/global/variables';

.component {
  background: $white100fontColor;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  max-width: 470px;
  padding: 10px;

  @include not-a-desktop {
    margin: 0 auto;
  }

  @include for-big-phone-only {
    max-width: 288px;
  }
}

.componentItem {
  margin: 16px 16px 26px;
}

.header {
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 40px;
  text-align: center;

  @include change-zoom {
    font-size: 26px;
    line-height: 39px;
    margin-bottom: 20px;
  }

  @include for-big-phone-only {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 16px;
  }
}

.grid {
  display: grid;
  grid-gap: 15px 25px;
  margin-bottom: 30px;
  grid-template-columns: 203px 203px;
  justify-content: center;

  @include change-zoom {
    grid-gap: 15px;
    margin-bottom: 20px;
  }

  @include not-a-desktop {
    grid-template-columns: 170px 170px;
  }

  @include for-big-phone-only {
    grid-template-columns: 203px;
    grid-column-gap: 0;
  }
}

.fullWidth {
  grid-column: span 2;
}

.browse {
  font-weight: 500;
  margin-bottom: 15px;

  @include change-zoom {
    font-size: 14px;
    line-height: 21px;
  }

  @include not-a-desktop {
    display: none;
  }
}
