@import '../../../static/scss/global/variables';

.imageLink {
  width: 270px;
  height: 180px;
  display: block;
  @include change-zoom {
    width: 216px;
    height: 144px;
  }

  @include not-a-desktop {
    width: 100%;
  }
}

.image {
  object-fit: cover;
  width: 100%;
  height: 180px;
  margin-bottom: 16px;
  @include change-zoom {
    margin-bottom: 10px;
    height: 144px;
  }
}

.wrapperDateTitle {
  border-left: 4px solid $themeColor;
  padding-left: 15px;
  margin-bottom: 16px;
  margin-top: 16px;

  @include change-zoom {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.date {
  opacity: 0.4;
  font-size: 14px;
  line-height: 16px;
  font-family: $headerFont;
  font-weight: 500;
  margin-bottom: 10px;
}

.title {
  font-family: $headerFont;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  height: 60px;
  word-wrap: break-word;
  max-width: 250px;

  @include change-zoom {
    font-size: 16px;
    line-height: 21px;
    height: 45px;
  }
}

.desc {
  opacity: 0.4;
  margin-bottom: 16px;
  line-height: 23px;
  overflow: hidden;
  margin-top: 16px;

  @include change-zoom {
    margin-bottom: 10px;
    margin-top: 10px;
    line-height: 18px;
  }

  @include for-big-phone-only {
    font-size: 14px;
    line-height: 23px;
  }
}

.readMore {
  opacity: 0.8;
}
