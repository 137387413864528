@import '../../../static/scss/global/variables';

.buttonsWrapper {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: end;
  grid-column-gap: 24px;
}

.cancelButton {
  font-weight: 600;
  cursor: pointer;
  color: $darkSectionColorOp080;
}
