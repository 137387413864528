@import '../../../static/scss/global/variables';

.wrapperComponent {
  position: relative;
  background-color: $darkestSectionColor;
}

.wrapperContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 75px;
  flex-shrink: 0;

  @include change-zoom {
    min-height: 60px;
  }
}

.menuForDesktop {
  display: flex;

  @include change-zoomLarge {
    margin-left: 110px;
  }

  @media (min-width: 900px) and (max-width: 1399px) {
    margin-left: 95px;
  }

  @include for-tablet-only {
    display: none;
  }
}

.navigationPhoneWrapper {
  position: absolute;
  display: block;
  top: 90%;
  left: 15px;
}

.buttonForTabletOnly {
  cursor: pointer;
  display: none;
  margin-right: 20px;
  z-index: 25;
  .icon {
    fill: #ffffff;
  }
  @include for-tablet-only {
    display: block;
  }
  @include for-big-phone-only {
    margin-right: 10px;
  }
}

.homePageLogoLarge {
  position: absolute;

  @include change-zoomLarge {
    top: -40px;
    left: 15px;
  }

  @media (min-width: 900px) and (max-width: 1399px) {
    top: -30px;
    left: 15px;
  }
}

.homePageLogo {
  justify-self: start;
  margin-right: 15px;
  @include for-big-phone-only {
    margin-right: 10px;
  }
}

.wrapperOptions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;

  .desktopOnly {
    @include not-a-desktop {
      display: none;
    }
  }
}
