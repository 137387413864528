@import '../../../static/scss/global/variables';

.tabTitles {
  display: flex;
  user-select: none;
}

.activeTabContent {
  position: relative;
}

.border {
  border: 1px solid $borderBaseColor;
}

.limitedHeight5 {
  max-height: 227px;
  overflow-y: auto;
}

.limitedHeight11 {
  max-height: 485px;
  overflow-y: auto;
}
