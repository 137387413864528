@import '../../../../../../static/scss/global/variables';

.headerWrapper {
  display: grid;
  grid-template-columns: minmax(200px, 700px) 68px;
  grid-column-gap: 30px;
  align-items: center;
  min-height: 68px;
  background: $lightSectionColor;
  overflow: hidden;
  @include for-big-phone-only {
    width: 288px;
    margin: 10px auto;
  }
}

.headerTitle {
  padding-left: 24px;
  @include for-big-phone-only {
    font-size: 14px;
    line-height: 21px;
  }
}

.downloadLink {
  display: flex;
  justify-self: stretch;
  align-self: stretch;
  position: relative;
  justify-content: center;
  z-index: 2;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: all 0.2s;
    transform: skew(-30deg) scale(1.5, 1) translateX(5px);
    background-color: #ececec;
    opacity: 0;
  }

  &:hover:before {
    opacity: 1;
  }

  & div {
    display: flex;
    align-self: center;
  }
}
