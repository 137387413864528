@import '../../../static/scss/global/variables';

.cardWrapper {
  max-width: 370px;
  margin: 0 auto;

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background: $white100fontColor;
    border: 1px solid #ededed;
    box-sizing: border-box;
    border-radius: 16px;
    height: 225px;
    width: 370px;
    padding: 20px 16px 16px;

    @include change-zoom {
      width: 288px;
      height: 199px;
      margin: 0 auto;
    }

    @include for-big-phone-only {
      width: 288px;
      height: 199px;
      margin: 0 auto;
    }
  }
}

.starWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;

  .star {
    margin: 0 4px;
  }
}
.opinion {
  font-family: $mainFont;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: $darkSectionColor;
  flex-grow: 1;
  word-wrap: break-word;
  text-align: left;

  @include change-zoom {
    display: none;
  }
}

.opinionForTablet {
  display: none;

  @include change-zoom {
    text-align: left;
    display: block;
    font-family: $mainFont;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: $darkSectionColor;
    flex-grow: 1;
    word-wrap: break-word;
  }
}

.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-family: $mainFont;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: $darkSectionColor;
  opacity: 0.8;

  @include change-zoom {
    font-size: 14px;
    line-height: 16px;
  }

  @include for-big-phone-only {
    font-size: 14px;
    line-height: 16px;
  }
}

.logoCompany {
  width: 110px;
  height: 45px;
  background: no-repeat bottom right;
  background-size: contain;
}
