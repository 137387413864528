@import '../../../static/scss/global/variables';

.wrapperSection {
  background-color: $darkSectionColor;
  color: $white100fontColor;

  @include for-tablet-only {
    padding-bottom: 10px;
  }
}

.wrapperContainer {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: auto;
  justify-content: center;
  min-height: 44px;
  align-items: center;
  @include change-zoom {
    min-height: 38px;
  }
  span {
    text-align: center;
  }

  .naviFooter {
    text-transform: capitalize;
    color: $white100fontColor;

    &:after {
      display: none;
    }
    &.active {
      color: $white100fontColor;

      &:after {
        display: none;
      }
    }
  }
  .naviFooter,
  span {
    font-size: 14px;
    font-family: $secondaryFont;
    font-style: normal;
    font-weight: normal;
    opacity: 0.8;
    margin-left: 12px;
    margin-right: 12px;

    &:nth-child(-n + 2):hover {
      opacity: 1;
    }
  }

  button {
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}

.titlePopup {
  color: black;
  margin-bottom: 32px;
  font-weight: bold;
  font-size: 24px;
}

.textPopup {
  color: black;
}
