@import '../../../static/scss/global/variables';

.wrapperComponent {
  display: block;
  background: $white100fontColor;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  transition: all 0.4s;
  position: relative;
  width: 270px;
  text-align: left;
  margin: 0 auto;
  min-height: 200px;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
  }
}

.wrapperInfo {
  padding: 15px 15px 20px 15px;
  border-bottom: 1px solid $borderBaseColor;
}

.titlePromptWrapper {
  margin-bottom: 10px;
  display: block;
  position: relative;

  @include change-zoom {
    margin-bottom: 0;
  }
}

.title {
  overflow: hidden;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;

  @include change-zoom {
    font-size: 14px;
  }
}

.wrapperPriceDate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  @include change-zoom {
    margin-bottom: 10px;
  }
}

.price {
  font-weight: bold;
  font-size: 16px;
  color: $themeColor;
  @include change-zoom {
    font-size: 14px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  min-height: 150px;
}

.image {
  width: 100%;
  height: 180px;
  border-radius: 16px 16px 0 0;
}

.averagePrice {
  color: $darkSectionColorOp080;
}

.shipping {
  color: $darkSectionColorOp080;
}

.wrapperSourceOfSupply {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include change-zoom {
    height: 40px;
  }
}

.averagePrice {
  margin-bottom: 15px;
  @include change-zoom {
    margin-bottom: 10px;
  }
}

.estShipping {
  margin-bottom: 35px;
  @include change-zoom {
    margin-bottom: 10px;
  }
}

.wrapperPriceDateText {
  color: #555b63;
}
