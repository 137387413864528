@import '../../../static/scss/global/variables';

.eai-input {
  position: relative;
}

.eai-input__label {
  background: $white100fontColor;
  position: absolute;
  transition: all 0.2s;
  color: $mainFontColorOp080;
  padding: 0 0;
  top: 12px;
  left: 10px;
  cursor: text;
  opacity: 0.6;
  font-size: 16px;

  .eai-input__active & {
    color: $themeColor;
    left: 8px;
    transform: translate(0, -18px);
    font-size: 12px;
    padding: 0 3px;
    opacity: 1;
  }
}

.eai-input__input {
  height: 40px;
  border: 1px solid $borderBaseColor;
  border-radius: 4px;
  //padding: 0 34px 0 8px;
  display: block;
  width: 100%;
  caret-color: $themeColor;
  transition: 0.2s;
  padding: 0 0 0 8px;
  -webkit-appearance: none;
  opacity: 1;

  .eai-input__activated & {
    border: 1px solid $themeColor;
  }

  &.inputOnError {
    border: 1px solid $errorColor;
    color: $errorColor;

    &:hover {
      border: 1px solid $errorColor;
      color: $errorColor;
    }
  }
}
