@import '../../../static/scss/global/variables';

.section {
  min-height: 200px;
  background: $darkSectionColor;
  padding: 56px 0;

  @include change-zoom {
    padding: 30px 0;
  }
}

.grid {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 50px;
  grid-template-columns: repeat(auto-fit, 280px);
  justify-content: space-between;

  @include change-zoom {
    grid-gap: 30px;
  }
  @include not-a-desktop {
    justify-content: center;
  }
}

.sectionHeaderDynamic {
  margin-bottom: 35px;

  @include change-zoom {
    margin-bottom: 20px;
  }
}

.lazyLoadImage {
  margin-bottom: 20px;
  width: 54px;
  height: 54px;

  @include change-zoom {
    margin-bottom: 15px;
    width: 45px;
    height: 45px;
  }
}
