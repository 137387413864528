@import '../../../../../../static/scss/global/variables';
.container {
  position: relative;
}

.buttonControlWrapper {
  display: none;
  position: relative;
  margin-bottom: 20px;
  @media (max-width: 999px) {
    display: block;
  }
}

.formWrapper {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  z-index: 27;
  position: fixed;
  top: 0;
  left: 0;
  //overflow: scroll;
  display: none;

  &.active {
    display: block;
    z-index: 27;
  }
}

.formHeader {
  background: #2a323c;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  color: #ffffff;
  justify-content: space-between;
  padding: 10px 16px;
  align-items: center;
  font-family: $headerFont;

  .formHeaderCloseIcon {
    fill: #fff;
  }

  .formHeaderTitle {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
  }

  .formHeaderResetButton {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}

.formBody {
  background: #ffffff;
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.buttonWrapper {
  padding: 15px;
}
