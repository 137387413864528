@import '../../../static/scss/global/variables';

.wrapperComponent {
  display: grid;
  grid-template-columns: 18px auto;
  grid-column-gap: 11px;
  user-select: none;
}

.wrapperIcon {
  position: relative;
}

.icon {
  opacity: 0.2;
  transition: 0.1s;
  display: block;

  &.active {
    opacity: 1;
  }
}

.iconOverflow {
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  background-color: $white100fontColor;
  transform: scale(1);
  transition: 0.1s;

  &.active {
    transform: scale(0);
  }
}

.content {
  font-size: 14px;
  line-height: 16px;
  opacity: 0.8;
  margin-top: 1px;
}
