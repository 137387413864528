@import '/../../../static/scss/global/variables';

.wrapperComponent {
  z-index: 11;
  width: 200px;
  background: $white100fontColor;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 0 16px;
  display: none;

  &:hover {
    border-radius: 4px;
  }

  &.active {
    display: block;
  }
}

.listSection {
  padding: 8px 0;
  border-bottom: 1px solid $borderBaseColor;

  &.last {
    border-bottom: none;
  }
}

.listItem {
  color: black;
  height: 38px;
  transition: 0.3s;
  cursor: pointer;
  margin: 0 -16px;
  padding: 16px;
  width: calc(100% + 32px);

  &:hover {
    background: $themeColor;
    color: $white100fontColor;
  }
}
