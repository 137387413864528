.m-auto {
  margin: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mr-auto {
  margin-right: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}

$minMarginValue: -100;
$maxMarginValue: 200;

@for $i from $minMarginValue through $maxMarginValue {
  .m-#{$i} {
    margin: $i + px;
  }
  .mx-#{$i} {
    margin-left: $i + px;
    margin-right: $i + px;
  }
  .my-#{$i} {
    margin-top: $i + px;
    margin-bottom: $i + px;
  }
  .mt-#{$i} {
    margin-top: $i + px;
  }
  .mr-#{$i} {
    margin-right: $i + px;
  }
  .mb-#{$i} {
    margin-bottom: $i + px;
  }
  .ml-#{$i} {
    margin-left: $i + px;
  }
  $i: $i + 5;
}

@media (min-width: $phoneWidth) {
  @for $i from $minMarginValue through $maxMarginValue {
    .m-ph-#{$i} {
      margin: $i + px;
    }
    .mx-ph-#{$i} {
      margin-left: $i + px;
      margin-right: $i + px;
    }
    .my-ph-#{$i} {
      margin-top: $i + px;
      margin-bottom: $i + px;
    }
    .mt-ph-#{$i} {
      margin-top: $i + px;
    }
    .mr-ph-#{$i} {
      margin-right: $i + px;
    }
    .mb-ph-#{$i} {
      margin-bottom: $i + px;
    }
    .ml-ph-#{$i} {
      margin-left: $i + px;
    }
    $i: $i + 5;
  }
}

@media (min-width: $tableWidth) {
  @for $i from $minMarginValue through $maxMarginValue {
    .m-ta-#{$i} {
      margin: $i + px;
    }
    .mx-ta-#{$i} {
      margin-left: $i + px;
      margin-right: $i + px;
    }
    .my-ta-#{$i} {
      margin-top: $i + px;
      margin-bottom: $i + px;
    }
    .mt-ta-#{$i} {
      margin-top: $i + px;
    }
    .mr-ta-#{$i} {
      margin-right: $i + px;
    }
    .mb-ta-#{$i} {
      margin-bottom: $i + px;
    }
    .ml-ta-#{$i} {
      margin-left: $i + px;
    }
    $i: $i + 5;
  }
}

@media (min-width: $netbookWidth) {
  @for $i from $minMarginValue through $maxMarginValue {
    .m-ne-#{$i} {
      margin: $i + px;
    }
    .mx-ne-#{$i} {
      margin-left: $i + px;
      margin-right: $i + px;
    }
    .my-ne-#{$i} {
      margin-top: $i + px;
      margin-bottom: $i + px;
    }
    .mt-ne-#{$i} {
      margin-top: $i + px;
    }
    .mr-ne-#{$i} {
      margin-right: $i + px;
    }
    .mb-ne-#{$i} {
      margin-bottom: $i + px;
    }
    .ml-ne-#{$i} {
      margin-left: $i + px;
    }
    $i: $i + 5;
  }
}

@media (min-width: $laptopWidth) {
  @for $i from $minMarginValue through $maxMarginValue {
    .m-la-#{$i} {
      margin: $i + px;
    }
    .mx-la-#{$i} {
      margin-left: $i + px;
      margin-right: $i + px;
    }
    .my-la-#{$i} {
      margin-top: $i + px;
      margin-bottom: $i + px;
    }
    .mt-la-#{$i} {
      margin-top: $i + px;
    }
    .mr-la-#{$i} {
      margin-right: $i + px;
    }
    .mb-la-#{$i} {
      margin-bottom: $i + px;
    }
    .ml-la-#{$i} {
      margin-left: $i + px;
    }
    $i: $i + 5;
  }
}

@media (min-width: $desktopWidth) {
  @for $i from $minMarginValue through $maxMarginValue {
    .m-de-#{$i} {
      margin: $i + px;
    }
    .mx-de-#{$i} {
      margin-left: $i + px;
      margin-right: $i + px;
    }
    .my-de-#{$i} {
      margin-top: $i + px;
      margin-bottom: $i + px;
    }
    .mt-de-#{$i} {
      margin-top: $i + px;
    }
    .mr-de-#{$i} {
      margin-right: $i + px;
    }
    .mb-de-#{$i} {
      margin-bottom: $i + px;
    }
    .ml-de-#{$i} {
      margin-left: $i + px;
    }
    $i: $i + 5;
  }
}
