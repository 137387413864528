@import './variables';

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
}

.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
}
