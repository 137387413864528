@import '../../../static/scss/global/variables';
.wrapperContainer {
  display: flex;
  flex-direction: column;

  @include for-tablet-only {
    order: -1;
    margin-bottom: 20px;
    flex-direction: row;
  }

  @include for-big-phone-only {
    flex-direction: column;
  }
}

.navigationItemFooter {
  @include change-zoom {
    padding: 0 8px 10px;
  }
}
