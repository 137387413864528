@import '../../../static/scss/global/variables';

.eaiInput {
  position: relative;

  &.eai-input__disabled {
    opacity: 0.4;
  }
}

.eai-input__label {
  background: $white100fontColor;
  position: absolute;
  transition: all 0.2s;
  color: $mainFontColor;
  opacity: 0.6;
  padding: 0 0;
  top: 10px;
  left: 10px;
  cursor: text;

  .eai-input__active & {
    color: $themeColor;
    left: 8px;
    top: 12px;
    transform: translate(0, -18px);
    padding: 0 3px;
    font-size: 12px;
    opacity: 1;
  }

  .eai-input__disabled & {
    color: $mainFontColorOp080;
    cursor: not-allowed;
  }

  .eai-input__error & {
    color: $errorColor;
  }
}

.eai-input__input {
  margin-top: 2.5px;
  height: 37.5px;
  border: 1px solid $borderBaseColor;
  border-radius: 4px;
  padding: 0 34px 0 8px;
  display: block;
  width: 100%;
  caret-color: $themeColor;
  transition: 0.2s;

  .eai-input__activated & {
    border: 1px solid $themeColor;
  }

  .eai-input__disabled & {
    cursor: not-allowed;
    color: $mainFontColorOp080;
  }

  .eai-input__error & {
    border-color: $errorColor;
  }
}

.helpText {
  font-family: $mainFont;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;

  margin-left: 10px;
  color: $darkSectionColor;
  opacity: 0.6;
}

.errorText {
  font-family: $mainFont;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: $errorColor;
  margin-left: 10px;
}
