@import '../../../static/scss/global/variables';

.wrapperComponent {
  padding: 30px 0 60px;
  background-position: center;
  background-size: cover;
  text-align: center;
  @include for-tablet-only {
    padding: 15px 10px 40px;
  }
}

.header {
  margin: 0 auto 20px auto;
  text-transform: none;

  @include for-tablet-up {
    font-size: 32px;
    line-height: 48px;
  }
}

.subTitle {
  max-width: 750px;
  margin: 0 auto 40px auto;
  font-size: 20px;
  line-height: 23px;
  color: $white100fontColor;
  @include for-tablet-only {
    font-size: 18px;
    line-height: 21px;
  }
}

.homePageLink {
  display: inline-flex;
  min-width: 170px;
  height: 38px;
  text-transform: uppercase;
  font-family: $buttonFont;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  color: $white100fontColor;
  background-color: $themeColor;

  &:hover {
    background-color: #ffb230;
  }

  @include for-big-phone-only {
    min-width: 288px;
  }
}
