@import '../../../static/scss/global/variables';

.header {
  text-transform: uppercase;
  text-align: center;
  font-size: 34px;
  line-height: 51px;
  font-weight: 700;

  @include change-zoom {
    font-size: 28px;
    line-height: 0;
  }

  @include for-big-phone-only {
    font-size: 20px;
    line-height: 30px;
  }
}

.light {
  color: $white100fontColor;
}
