@import '../../../../../static/scss/global/variables';

.buttonHome {
  width: 100%;
}

.iconTitleWrapper {
  text-align: center;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 10.6px;
    margin-top: 3px;
  }

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: $darkSectionColor;
  }
}
