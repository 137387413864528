@import '../../../../../../static/scss/global/variables';

.wrapperContent {
  padding-left: 6px;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  align-items: center;
}

.messageText {
  font-family: $secondaryFont;
  color: #000000;
}

.link {
  font-family: $secondaryFont;
  color: $themeColor;
}
