@import '../../../../../../static/scss/global/variables';

.wrapper {
  display: flex;
}

.item {
  cursor: pointer;
  margin-left: 16px;
  width: 40px;
  height: 40px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }

  use {
    fill: white;
  }
}

.active {
  use {
    fill: #eca52c;
  }
}

.disabled {
  cursor: not-allowed;
}
