@import '../../../static/scss/global/variables';

.wrapperSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.messageIcon {
  padding: 5px 10px;
  cursor: pointer;
  @include for-big-phone-only {
    display: none !important;
  }
}

.userCard {
  padding: 5px 0 5px 10px;
  @include for-big-phone-only {
    padding: 0;
  }
}
