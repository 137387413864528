@import '../../../static/scss/global/variables';

.wrapperComponent {
  width: 150px;
  position: relative;

  @include for-big-phone-only {
    position: absolute;
    width: calc(100% - 32px);
    left: 16px;
  }
}

.active {
  z-index: 25;
}

.submit {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $darkSectionColor;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.input {
  display: block;
  background-color: $darkSectionColor;
  color: $darkSectionColor;
  height: 40px;
  border-radius: 20px;
  margin-left: auto;
  caret-color: $themeColor;
  width: 40px;
  transition: all 0.3s;

  .active & {
    width: 100%;
    padding: 0 40px 0 16px;
    color: $white100fontColor;
  }
}

.icon {
  transition: all 0.3s;
  display: block;
  width: 21px;
  height: 21px;
  @include for-big-phone-only {
    width: 16px;
    height: 16px;
  }

  .active & {
    fill: $themeColor;
  }
}
