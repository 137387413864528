@import '../../../../../static/scss/global/variables';

.itemSideMenu {
  color: $white100fontColor;
  display: flex;
  padding: 16px 32px;
  margin-left: -30px;
  user-select: none;

  .wrapperIcon {
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin-right: 10px;

    svg {
      opacity: 0;

      use {
        fill: $themeColor;
      }
    }
  }

  .wrapperTitle {
    font-family: $headerFont;
    color: rgba($white100fontColor, 0.7);
    display: flex;
    flex-direction: column;

    & > :nth-child(1) {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }

    & > :nth-child(2) {
      margin-top: 4px;
    }

    & > :nth-child(2),
    & > :nth-child(3) {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }

    & > :nth-child(3) {
      color: $themeColor;
      text-transform: lowercase;
    }

    // &:hover {
    //   opacity: 1;
    // }
  }

  &.active {
    box-shadow: 4px 0 0 0 $themeColor inset;
    background-color: rgba(255, 255, 255, 0.08);

    .wrapperTitle {
      color: $themeColor;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.12);
    }
  }

  &.inactive {
    .wrapperIcon {
      svg {
        opacity: 1;
      }
    }

    .wrapperTitle {
      opacity: 0.4;

      & > :nth-child(1) {
        font-weight: 500;
      }

      & > :nth-child(3) {
        color: $white100fontColor;
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }
}
