.container {
  width: 80vw;
  @media (max-width: 600px) {
    width: unset;
  }
  height: 100%;
  max-height: 90vh;
  display: grid;
  grid-template-rows: 51px auto 40px;
  row-gap: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.popupCloseIcon {
  align-self: flex-start;
}

.containerTable {
  overflow: auto;

  @media (max-width: 1440px) {
    max-height: 70vh;
  }
}

.title {
  font-weight: bold;
  margin-bottom: 24px;
}

.row_carPreview {
  margin: 4px 0;
}

.carPreview_image {
  width: 80px;
  height: 50px;
  object-fit: cover;
}

.card_container {
  padding: 8px;
}

.loader {
  height: 50px;
  margin-top: 0;
}

.popover_paper {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
