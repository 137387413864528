@import '../../../../../../../static/scss/global/variables';
$cardWidth: 320px;

.card {
  display: flex;
  flex-shrink: 1;
  background: $white100fontColor;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  transition: 0.4s;
  height: 209px;
  margin-bottom: 16px;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
  }

  .leftBlock {
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;

    @include not-a-desktop {
      flex-direction: column;
    }
  }

  @include not-a-desktop {
    flex-direction: column;
    height: auto;
  }
}

.title {
  font-family: $headerFont;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
  width: auto;
  display: inline-block;
}

.img {
  position: relative;
  flex: 0 0 275px;
  background-position: center;
  background-size: cover;
  display: block;
  border-radius: 8px 0 0 8px;

  @include not-a-desktop {
    flex: 0 0 auto;
  }

  & img {
    @include not-a-desktop {
      border-radius: 8px;
    }
  }
}

.info {
  margin: 25px 16px;
  flex-grow: 1;
}

.propertyWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;

  @include not-a-desktop {
    flex-direction: column;
  }

  @include for-big-phone-only {
    margin-top: 15px;
  }
}

.name {
  color: $darkSectionColor;
  opacity: 0.4;
  margin-bottom: 12px;

  &:after {
    content: ':';
  }
}

.value {
  font-weight: 500;
}

.buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 25px;
}

.actions {
  position: absolute;
  top: 5px;
  right: 5px;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 1));
  opacity: 0;
  cursor: pointer;

  .card:hover & {
    opacity: 1;
  }

  .actionsIcon {
    fill: white;
  }
}

.positioningStyleAuctionDate {
  justify-self: center;
  font-weight: 500;
  position: relative;
  top: 20px;
  display: flex;
  align-items: center;

  @include for-big-phone-only {
    top: 0;
  }
}

.buttonsPosition {
  position: relative;
  display: grid;
  grid-template-columns: 170px;
  grid-row-gap: 16px;
  margin-bottom: 30px;
  @include not-a-desktop {
    grid-template-columns: minmax(288px, 260px);
  }
  @media (max-width: 340px) {
    grid-template-columns: 268px;
  }
}

.actionsForDesktop {
  position: absolute;
  top: -18px;
  right: -33px;
  cursor: pointer;

  & .icon {
    fill: #0f1318;
    opacity: 0.4;
    transition: opacity 0.3s ease-out;

    &:hover {
      opacity: 1;
    }
  }
}

.price {
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
  background-color: $lightSectionColor;
}

.shoppingManagement {
  margin: 24px 40px 0 10px;

  @include not-a-desktop {
    margin: 0 auto;
  }
}

.CalIcon {
  position: relative;
  left: -6px;
  top: -2px;
  transition: 0.3s;

  *:hover > & {
    fill: $themeColor;
  }
}

.linkImg {
  display: block;
  background-color: #e6e6e6;
}

.auctionDateWrapper {
  display: flex;
}
