@import '../../../../../static/scss/global/variables';

.root {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 30px;

  @include change-zoom {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 15px;
  }

  @media (max-width: 500px) {
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 19px;

    @include change-zoom {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 15px;
    }

    @media (max-width: 500px) {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
