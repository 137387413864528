@import '../../../static/scss/global/variables';

.wrapperSection {
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.name {
  color: $white100fontColor;
  @include for-tablet-only {
    font-size: 14px;
    text-align: right;
  }
}

.icon {
  transition: 0.3s;
  padding-top: 3px;

  &.active {
    transform: rotate(-180deg);
  }
}

.optionsWrapper {
  position: absolute;
  z-index: 11;
  top: 110%;
  right: 0;
}
