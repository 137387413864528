@import '../../../../../../../static/scss/global/variables';
.galleryList {
  display: flex;
  justify-content: center;
  @include for-big-phone-only {
    width: 288px;
    margin: 10px auto;
  }
}

.carousel {
  display: grid;
  grid-template-columns: 40px auto 40px;
  grid-column-gap: 20px;
  align-items: center;
}

.listItemWrapper {
  width: 150px;
  margin: 0 8px;
  @include for-big-phone-only {
    max-width: 92px;
  }
}

.carouselItemsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
}

.galleryItem {
  width: 100%;
  padding-top: 67%;
  background-position: center;
  background-size: cover;
  opacity: 0.5;
  cursor: pointer;

  &.active {
    opacity: 1;
  }
}
