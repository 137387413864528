@import '../../../static/scss/global/variables';

.style {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: $zIndexHeader;

  @include change-zoom {
    border-bottom: 2px solid $themeColor;
  }
}

.float {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  @include for-tablet-only {
    border-bottom: 2px solid $themeColor;
  }
}

.isNotBorder {
  border-bottom: none;
}
