@import '../../../../../../static/scss/global/variables';

.wrapperComponent {
  display: block;
  background: $white100fontColor;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  transition: all 0.4s;
  position: relative;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
  }
}

.image {
  display: block;
  height: 180px;
  background-position: center;
  background-size: cover;
  position: relative;
  border-radius: 16px 16px 0 0;
}

.wrapperInfo {
  padding: 15px 15px 20px 15px;
  border-bottom: 1px solid $borderBaseColor;
}

.wrapperPriceDate {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.titlePromptWrapper {
  display: block;
  position: relative;
}

.title {
  overflow: hidden;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
}

.price {
  font-weight: bold;
  font-size: 18px;
  color: $themeColor;
}

.shippingTitle {
  color: $darkSectionColorOp040;
  margin-right: 8px;
}

.shippingPrice {
  font-weight: 500;
  margin-right: 6px;
}

.wrapperSourceOfSupply {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modelsOfActionPosition {
  position: absolute;
  top: 16px;
  right: 16px;
  opacity: 0;
  transition: opacity 0.4s;

  .wrapperComponent:hover & {
    opacity: 1;
  }

  &.modelsOfActionActive {
    opacity: 1;
  }
}
