@import '../../../static/scss/global/variables';

.root {
  font-family: $headerFont;
  font-weight: bold;
  color: $darkSectionColor;
}

.sizeSmall {
  font-size: 24px;
  line-height: 36px;
  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
  }
}

.sizeBig {
  font-size: 34px;
  line-height: 51px;
  margin-bottom: 35px;
  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px;
  }
}

.alignCenter {
  text-align: center;
}
