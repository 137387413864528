@import '../../../../../../../static/scss/global/variables';

.wrapper {
  display: flex;
  align-items: center;
}

.image {
  display: block;
  position: relative;
  width: 100%;
  user-select: none;
  object-fit: cover;
}

.verticalCardContext {
  height: 180px;
  border-radius: 16px 16px 0 0;
}

.horizontalCardContext {
  height: 209px;
  border-radius: 8px 0 0 8px;
}

.watchlistCard {
  height: 209px;
  border-radius: 8px 0 0 8px;
  background-size: cover;
}

.wrapperArray {
  position: absolute;
  z-index: 2;
  opacity: 0;
  border-radius: 0 4px 4px 0;
  border-width: 3px 3px 3px 0;
  border-style: solid;
  border-color: transparent;

  //&:hover {
  //  border-color: #ffffff;
  //}

  .wrapper:hover & {
    opacity: 1;
  }
}

.wrapperRightArray {
  right: 0;
}

.arrow {
  fill: $white100fontColor;
}
