@import '../../../static/scss/global/variables';

.style {
  position: absolute;
  left: calc(100% - 25px);
  top: 19px;
  transform: translateY(-50%) rotate(180deg);
  transition: all 0.2s;
  cursor: pointer;
}

.activated {
  transform: translateY(-50%) rotate(0deg);
}
