@import '../../../static/scss/global/variables';

.wrapperSection {
  background-color: $darkSectionColor;
}

.wrapperContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 45px;

  @include change-zoom {
    min-height: 35px;
  }
}

.openingHours {
  margin-right: 15px;
  color: $white100fontColor;
  opacity: 0.8;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  font-weight: normal;
  transition: opacity 0.1s ease-out;

  @include change-zoomLarge {
    padding-left: 115px;
  }

  @media (min-width: 900px) and (max-width: 1399px) {
    padding-left: 100px;
  }

  .bigPhoneUp {
    @include for-tablet-only {
      display: none;
    }
  }

  .desktopOnly:hover {
    opacity: 1;
  }
}
