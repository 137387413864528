@import '../../../static/scss/global/variables';

.loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 20%;
}

.car {
  .carBody {
    animation: shake 0.2s ease-in-out infinite alternate;
  }

  .carLine {
    stroke-dasharray: 22;
    animation: line 0.8s ease-in-out infinite;
    animation-fill-mode: both;

    .carLineTop {
      animation-delay: 0s;
    }

    .carLineMiddle {
      animation-delay: 0.2s;
    }

    .carLineBottom {
      animation-delay: 0.4s;
    }
  }
}

@keyframes shake {
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(3%);
  }
}

@keyframes line {
  0% {
    stroke-dashoffset: 22;
  }

  25% {
    stroke-dashoffset: 22;
  }

  50% {
    stroke-dashoffset: 0;
  }

  51% {
    stroke-dashoffset: 0;
  }

  80% {
    stroke-dashoffset: -22;
  }

  100% {
    stroke-dashoffset: -22;
  }
}
