@import '../../../static/scss/global/variables';

.wrapperComponent {
  display: flex;
  //align-items: center;
  //justify-content: center;

  .iconLogo {
    width: 48px;
    height: 48px;
    background-repeat: no-repeat;
    background-size: contain;

    @include for-big-phone-only {
      width: 135px;
    }

    @include change-zoomLarge {
      width: 110px;
      height: 110px;
    }

    @media (min-width: 900px) and (max-width: 1399px) {
      width: 85px;
      height: 85px;
    }
  }
}
