@import '../../../static/scss/global/variables';

.tab {
  display: flex;
  min-height: 48px;
  background: $darkestSectionColor;
  color: $white100fontColor;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #2b3745;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 16px;
    padding-left: 5px;
  }
}

.disabled {
  cursor: auto;
  opacity: 0.5;

  &:hover {
    background: $darkestSectionColor;
    opacity: 0.5;
  }
}

.equalTabWidth {
  flex: 1 1;
}

.active {
  background: $themeColor;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    border: 8px solid transparent;
    border-top: 8px solid $themeColor;
    z-index: 1;
  }

  &:hover {
    background: $themeColor;
  }
}
