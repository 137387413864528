@import '../../../static/scss/global/variables';

.wrappedContainer {
  .title {
    font-family: $headerFont;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    color: $white100fontColor;
    opacity: 0.8;
    margin-bottom: 16px;
    display: block;
    margin-top: 5px;

    @include change-zoom {
      margin-top: 0;
    }
  }

  .newsList {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 39px;
    grid-row-gap: 16px;

    @include for-big-phone-only {
      grid-template-columns: none;
      grid-template-rows: auto;
      grid-column-gap: 0;
      grid-row-gap: 16px;
    }
  }
}
