@import '../../../static/scss/global/variables';

.wrapperSection {
  position: relative;
}

.bell {
  opacity: 0.6;
  display: block;
  &:hover {
    opacity: 1;
  }
}

.hasMessages {
  &:hover {
    .bell {
      opacity: 1;
    }
  }
  &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: $themeColor;
    border: 1px solid $darkSectionColor;
    border-radius: 50%;
    top: 2px;
    left: 18px;
  }
}
