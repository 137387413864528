@import '../../../static/scss/global/variables';

.wrapperComponent {
  position: relative;
  display: inline-block;
}

.shippingIcon {
  position: relative;
  top: 2px;
  left: 6px;
  opacity: 0.6;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
}

.promptWidth {
  width: 276px;
}
