.string {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 45px;
  padding: 5px 24px;

  @media (min-width: 1100px) and (max-width: 1200px) {
    padding: 5px 16px;
  }
}
