@import '../../../static/scss/global/variables';

.sectionCollapse {
  border-bottom: 1px solid #ededed;
  padding: 10px 16px;
}

.sectionCollapseHeader {
  display: flex;
  justify-content: space-between;
}

.sectionCollapseTitle {
  font-family: $mainFont;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2a323c;
}

.iconArrow {
  width: 22px;
  height: 14px;
}

.section {
  width: 100%;
  margin-top: 10px;
  display: none;

  &.active {
    display: block;
  }
}
