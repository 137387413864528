@import '../../../../../../static/scss/global/variables';

.wrapperComponent {
  position: absolute;
  z-index: 15;
  display: inline-block;
  background: $white100fontColor;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  border: 1px solid $borderBaseColor;
  font-family: $secondaryFont;
  padding: 16px;
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
}
