@import '../../../../../../static/scss/global/variables';

.name {
  color: rgba($darkSectionColor, 0.4);
  margin-bottom: 12px;

  &:after {
    content: ':';
  }
}

.value {
  font-weight: 500;
  word-wrap: break-word;
  position: relative;

  &.havePopup {
    cursor: pointer;
  }

  &:hover {
    .hoverPostfix {
      & > div {
        display: block;
      }
    }
  }
}

.postfix {
  display: inline-block;
  vertical-align: bottom;
}

.hoverPostfix {
  position: absolute;
  left: 0;
  top: 50%;
  min-width: 200px;
  & > div {
    display: none;
  }
}
