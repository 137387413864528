@import '../../../../../../static/scss/global/variables';

.inputWrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 16px;
  margin-top: 25px;
  @media (max-width: 499px) {
    grid-template-columns: 1fr;
    margin-top: 16px;
  }

  .input {
    margin-bottom: 25px;
    @media (max-width: 599px) {
      margin-bottom: 0;
    }
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .button {
    max-width: 160px;
  }
}

.errorMessage {
  margin: -15px 0 15px;
  color: $errorColor;
}

//.selectCustomized {
//  @media (max-width: 599px) {
//    max-width: 100px;
//  }
//}
