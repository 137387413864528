@import '../../../static/scss/global/variables';
@import '../../atoms/input/input.module.scss';

.eai-input-dropdown {
  position: relative;
}

.eai-input__label,
.eai-input__input {
  cursor: pointer;
}

.eai-input__input {
  text-transform: capitalize;
}

.noneWritable {
  caret-color: transparent;
}
