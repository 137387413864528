@import '../../../static/scss/global/variables';

.wrapperSection {
  background-color: $darkestSectionColor;
  color: $white100fontColor;
  min-height: 338px;
  padding: 45px 15px;
  @include change-zoom {
    padding: 30px 10px;
    min-height: auto;
  }

  @include for-tablet-only {
    padding-top: 15px;
  }
}
.linksSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @include change-zoom {
    margin-bottom: 0;
  }

  @include for-tablet-only {
    flex-direction: column;
    align-items: center;
  }
  @include for-big-phone-only {
    align-items: flex-start;
  }

  .address {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    & .addressLogo {
      margin-bottom: 10px;
    }
  }
}

.socialSection {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;

  @include for-tablet-only {
    flex-direction: column;
    align-items: center;
  }
  @include for-big-phone-only {
    align-items: flex-start;
  }
}
