@import '../../../../../../static/scss/global/variables';

.sourceOfSupply {
  font-family: $secondaryFont;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.positioningFlag {
  margin-right: 10px;
}
