@import '../../../static/scss/global/variables';

.wrapperComponent {
  min-height: 100px;
  background-color: #030405;
  padding: 48px 0;

  @include change-zoom {
    padding: 20px 0;
  }

  //@include for-desktop-up {
  //  padding-bottom: 48px;
  //}
}

.ratingWrapper {
  margin: 0 15px;

  .ratingImg {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.controlDots {
  margin-top: 50px;
  display: flex;
  justify-content: center;

  .dot {
    width: 10px;
    height: 10px;
    box-shadow: none;
    background: rgba(white, 0.4);
    border-radius: 50%;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  .active {
    background: #eca52c;
    opacity: 1;
  }
}
