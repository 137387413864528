@import '../../../static/scss/global/variables';

.header {
  text-transform: uppercase;
  text-align: center;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  @include for-big-phone-only {
    font-size: 20px;
    line-height: 30px;
  }

  i {
    font-style: normal;

    strong {
      font-weight: 700;
      color: $themeColor;
    }
  }
}

.light {
  color: $white100fontColor;
}
