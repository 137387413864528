@import '../../../../../../static/scss/global/variables';

.searchOutcomeWrapper {
  display: flex;
  justify-content: space-between;
}

.searchOutcome {
  display: flex;
  align-items: center;
  z-index: 1;
  margin-bottom: 40px;

  @include change-zoom {
    margin-bottom: 25px;
  }
}

.outcomeTotal {
  font-size: 18px;
  line-height: 21px;
  padding-right: 8px;
  border-right: 1px solid $darkSectionColorOp040;
  margin-right: 8px;
  font-weight: 700;
}

.outcomeRegion {
  color: $mainFontColorOp080;
  margin-right: 8px;
}

.outcomeRegionCount {
  font-weight: 700;
}
