@import '../../../../../../static/scss/global/variables';

.buttonsHolder {
  display: grid;
  grid-template-columns: repeat(auto-fit, 170px);
  grid-gap: 30px;
  justify-content: space-between;

  @media (max-width: 820px) {
    grid-template-columns: 170px 170px;
    grid-template-rows: auto auto;
    grid-gap: 20px;
    justify-content: center;
  }
  @media (max-width: 400px) {
    grid-template-columns: 288px;
    grid-gap: 16px;
    justify-content: center;
  }
}

.howToRead {
  font-family: $headerFont;
  font-weight: 600;
  color: $darkSectionColor;
  opacity: 0.8;
}

.buttonIcon {
  display: block;
}

.button {
  width: 204px;
}

.popupLarge {
  position: fixed;
  top: 5%;
  //bottom: 0;
  left: calc(50% - 213px);
  //right: 0;
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 21;
  padding: 30px 0;
  width: 426px;
}
