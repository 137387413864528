@import '../../../../../../static/scss/global/variables';
.wrapperComponent {
  position: relative;
  padding-top: 45px;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.headerItem {
  text-transform: uppercase;
  opacity: 0.6;
  font-weight: 500;

  @include for-big-phone-only {
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
  }
}

.lot {
  width: 64px;
  font-weight: 500;
}
.averagePrice {
  width: 122px;
  font-weight: 500;
}
.lastPrice {
  width: 71px;
  font-weight: 500;
}

.valuesList {
  height: 180px;
  overflow-y: scroll;
}
