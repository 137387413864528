.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.forward {
  transform: rotate(180deg);
}

.icon {
  display: block;
}

.disabled {
  cursor: default;
}
