@import '../../../static/scss/global/variables';

.priority {
  overflow: visible;
  white-space: normal !important;

  span {
    overflow: visible !important;
  }
}

.tableHeader {
  .tableCell,
  th {
    text-transform: uppercase;
    color: rgba(42, 50, 60, 0.6);
    background-color: #f5f5f5;
    border: none;
  }
}

.gridWrapper {
  overflow-x: auto;
  div > div {
    overflow: visible;
  }
}

.insuranceDeactivated {
  color: rgba($darkestSectionColor, 0.5);
}

.insuranceActive {
  color: $darkestSectionColor;
}
