@import '../../../static/scss/global/variables';

.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 1021;
  padding: 30px 0;

  @include for-tablet-only {
    padding: 0;
  }
}

.popupOuterSpace {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.wrapperPopup {
  position: relative;
  z-index: 1001;
  background: $white100fontColor;
  margin: auto;
  border-radius: 16px;
  padding: 24px 0 24px;
  flex-grow: 0;
  flex-shrink: 1;
  box-sizing: border-box;
  //max-height: calc(100% - 20px);
  display: flex;

  @media (max-width: 699px) {
    padding: 10px 0 10px;
  }
}

.popupWindowSizeSmall {
  flex-basis: 360px;
  @media (max-width: 400px) {
    max-width: 280px;
  }
}

.popupWindowSizeLarge {
  flex-basis: 470px;
  @media (max-width: 500px) {
    max-width: 370px;
  }
  @media (max-width: 400px) {
    max-width: 288px;
  }
}

.popupBackgroundColorDark {
  background-color: rgba(0, 0, 0, 0.6);
}

.popupBackgroundColorGrey {
  background-color: rgba(0, 0, 0, 0.1);
}

.popupCloseIcon {
  position: absolute;
  z-index: 1001;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: auto;
  top: 0;
  right: 24px;
  width: 16px;
  height: 16px;
  background-position: center;
  @include for-big-phone-only {
    top: 16px;
    right: 17px;
  }
}

.iconPopup {
  fill: $darkestSectionColor;
}

.contentNoScroll {
  overflow: hidden;
  padding: 0 24px;
  flex-grow: 1;
}

.content {
  padding: 0 24px;
  flex-grow: 1;
  height: auto;
  //margin-bottom: 10px;

  @media (max-width: 699px) {
    //margin-bottom: 30px;
  }
}
