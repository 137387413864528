@import '../../../static/scss/global/variables';

.wrapperSection {
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  align-items: center;
  cursor: pointer;
  position: relative;
  @include for-big-phone-only {
    display: none;
  }

  &:hover {
    .pathIcon {
      opacity: 1;
    }
  }
}

.name {
  color: $white100fontColor;
}

.icon {
  transition: 0.3s;

  &.active {
    transform: rotate(-180deg);
  }
}

.pathIcon {
  padding: 5px 10px;
  cursor: pointer;
  position: relative;
  top: 1px;
  opacity: 0.6;
}
