@import '../../../../../../static/scss/global/variables';

.messageWrapper {
  background: $white100fontColor;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  border: 1px solid $borderBaseColor;
  font-family: $secondaryFont;
  padding: 10px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  width: 250px;
  color: #555b63;
}
