:global {
  .Mui-error .MuiSelect-selectMenu {
    border-color: #f44335 !important;
  }
}

.selectCustomized {
  position: relative;
}

.selectWrapper {
  position: absolute;
}
