@import '../../../../../../static/scss/global/variables';

.stringValue {
  flex: 1 1 auto;
  font-weight: 500;
  text-align: right;
  @media (max-width: 500px) {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
}
