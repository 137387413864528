@import '../../../../static/scss/global/variables';

.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: $white100fontColor;
  margin-bottom: 8px;
  @include change-zoom {
    font-size: 18px;
    margin-bottom: 0;
  }
  @include for-big-phone-only {
    font-size: 20px;
    line-height: 30px;
  }
}

.description {
  color: $white100fontColor;
  line-height: 19px;
  @include not-a-desktop {
    text-align: justify;
  }
  @include for-big-phone-only {
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0;
    word-spacing: -1px;
  }
}
