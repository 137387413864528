@import '../../../../static/scss/global/variables';

.wrapperComponent {
  width: 352px;
  margin: 0 auto;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);

  @media (max-width: 599px) {
    width: 280px;
  }
}

.card {
  display: flex;
  height: 64px;
  justify-content: center;

  &:hover .estimate {
    color: $darkSectionColor;
  }
}

.estimate {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 116px;
  background-color: $lightSectionColor;
  font-weight: bold;
  font-size: 40px;
  color: rgba($darkSectionColor, 0.6);
  font-family: $headerFont;
  transition: all 0.3s ease-out;
  border-radius: 8px 0 0 8px;
}

.content {
  background-color: $white100fontColor;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 12px;
  padding-bottom: 10px;
  width: 236px;
  transition: all 0.3s ease-out;
  border-radius: 0 8px 8px 0;
}
