@import '../../../static/scss/global/variables';

.section {
  display: flex;
  justify-content: flex-start;
  width: 1200px;
  margin: 0 auto;
  padding: 32px 15px 120px 0;
  @include change-zoom {
    padding: 32px 15px 30px 15px;
  }
}

.wrapper {
  max-width: 560px;
  min-width: 400px;
  @media (max-width: 450px) {
    min-width: 200px;
  }
}

.text {
  font-size: 14px;
  margin-bottom: 32px;
  line-height: 23px;
  font-weight: 500;
  margin-top: 23px;
}

.addButton {
  width: 44px;
  height: 44px;
}

.terms {
  font-style: italic;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 32px;
  color: #d5322a;
}

.fieldRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
}

.button {
  z-index: 2;
  cursor: pointer;
  width: 77px;
  height: 38px;
  border: 1px solid $themeColor;
  align-self: center;
  color: $themeColor;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: -1px;
  box-sizing: border-box;
}

.field {
  width: 467px;
  height: 40px;
  margin-top: -2px;
  div div input {
    height: 22px;
  }
}

.fieldWrapper {
  margin-bottom: 32px;
}

.title {
  text-transform: capitalize;
  text-align: center;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  @include for-big-phone-only {
    font-size: 20px;
    line-height: 30px;
  }

  .titleDark {
    font-style: normal;
    font-weight: 700;
    color: $themeColor;
  }
}
