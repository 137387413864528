@import '../../../static/scss/global/variables';

.btn {
  display: inline-flex;
  min-width: 170px;
  height: 38px;
  text-transform: uppercase;
  font-family: $buttonFont;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  color: $white100fontColor;

  //@include change-zoom {
  //  height: 38px;
  //}

  &.fullWidth {
    display: flex;
    width: 100%;
  }

  &.btn-type_disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &.btn-primary {
    background-color: $themeColor;

    &:hover {
      background-color: #ffb230;
    }

    &.btn-type_disabled:hover {
      background-color: $themeColor;
    }
  }

  &.btn-secondary {
    background-color: #c3c7d2;

    &:hover {
      background-color: #dadeeb;
    }

    &.btn-type_disabled:hover {
      background-color: #c3c7d2;
    }
  }

  &.btn-tertiary {
    font-family: $mainFont;
    font-weight: 400;
    background-color: $lightSectionColor;
    border-radius: 0;
    border: 1px solid $borderBaseColor;
    height: 35px;
    color: $darkestSectionColor;
    text-transform: initial;
    transition: background-color 0.2s, border 0.2s, color 0.1s;

    @include change-zoom {
      font-size: 14px;
    }

    &:hover {
      background-color: $themeColor;
      border: 1px solid $themeColor;
      color: $white100fontColor;

      & svg,
      & path {
        fill: $white100fontColor;
      }
    }

    &.btn-type_disabled:hover {
      background-color: $lightSectionColor;
      border: 1px solid $borderBaseColor;
      color: $darkestSectionColor;

      & svg,
      & path {
        fill: $darkestSectionColor;
      }
    }
  }

  &.btn-tertiary-contrariwise {
    font-family: $mainFont;
    font-weight: 400;
    background-color: $themeColor;
    border-radius: 0;
    border: 1px solid $themeColor;
    height: 35px;
    color: $white100fontColor;
    text-transform: initial;
    transition: background-color 0.2s, border 0.2s, color 0.1s;

    @include change-zoom {
      font-size: 14px;
    }

    & svg,
    & path {
      fill: #ffffff;
    }

    &:hover {
      background-color: $lightSectionColor;
      border: 1px solid $borderBaseColor;
      color: $darkestSectionColor;

      & svg,
      & path {
        fill: $darkestSectionColor;
      }
    }
  }
}
