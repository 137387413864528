@import '../../../static/scss/global/variables';

.cancel {
  font-family: $buttonFont;
  cursor: pointer;
  transition: all 0.3s;
  color: $white100fontColor;
  font-weight: 600;
  color: $darkSectionColor;
  opacity: 0.8;

  &:hover {
    color: #dadeeb;
  }
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
