@import '../../../static/scss/global/variables';

.address {
  display: flex;
  align-items: center;
  padding-top: 9px;
  padding-bottom: 9px;

  @include change-zoom {
    padding-top: 0;
  }

  & > :first-child {
    width: 20px;
    text-align: center;
    margin-right: 12px;
  }

  .text {
    font-family: $mainFont;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $white080fontColor;
    transition: all 0.3s ease-out;
  }

  svg {
    display: block;
    fill: #d4d6d8;
    transition: all 0.3s ease-out;
  }
}

.address:hover svg {
  fill: white;
}

.address:hover .text {
  color: white;
}
