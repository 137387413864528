@import '../../../../../../static/scss/global/variables';

.root {
}

.container {
  overflow-x: hidden;
  padding: 12px;
  width: 100%;
}

.divider {
  margin: 12px 0;
}
