@import '../../../static/scss/global/variables';

.root {
  display: block;
  position: relative;
}

.textarea {
  display: block;
  width: 100%;
  background: #ffffff;
  border: 1px solid $borderBaseColor;
  border-radius: 4px;
  padding: 16px;
  min-height: 124px;
  resize: none;

  .active & {
    border: 1px solid $themeColor;
  }

  .hasError & {
    border: 1px solid $errorColor;
  }
}

.label {
  position: absolute;
  top: 16px;
  left: 16px;
  background: #ffffff;
  transition: all 0.2s;
  color: $mainFontColor;
  opacity: 0.6;
  cursor: text;

  .active & {
    color: $themeColor;
    left: 8px;
    top: 12px;
    transform: translate(0, -18px);
    padding: 0 3px;
    font-size: 12px;
    opacity: 1;
  }

  .hasError & {
    color: $errorColor;
  }
}

.infoText {
  font-size: 11px;
  line-height: 13px;
  margin-left: 10px;
}

.helpText {
  @extend .infoText;
  opacity: 0.6;
}

.errorText {
  @extend .infoText;
  color: $errorColor;
}
