@import '../../../static/scss/global/variables';

.section {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #030405;
}

.grid {
  display: flex;
  padding: 150px 0;
  justify-content: space-between;

  @include change-zoom {
    padding: 90px 0;
  }

  @include not-a-desktop {
    flex-direction: column-reverse;
    padding: 56px 0 125px;
    justify-content: center;
  }

  @include for-tablet-up {
    padding: 56px 0 75px;
  }
}

.titleCol {
  @include not-a-desktop {
    align-self: flex-start;
    margin: 0 auto;
  }

  @include for-desktop-up {
    align-self: flex-start;
    width: 800px;
  }

  @include for-tablet-up {
    width: 470px;
  }

  .title {
    font-weight: 800;
    font-size: 42px;
    line-height: 63px;
    color: $white100fontColor;
    margin: 35px 0 25px 50px;
    max-width: 575px;
    text-transform: none;
    text-align: left;

    @include change-zoom {
      font-weight: 700;
      font-size: 34px;
      line-height: 51px;
      margin: 0 0 0 50px;
      max-width: 100%;
    }

    @include not-a-desktop {
      margin: 0 0 25px 0;
    }

    @include for-tablet-up {
      font-size: 34px;
      line-height: 51px;
    }

    @include for-big-phone-only {
      font-size: 20px;
      line-height: 30px;
      text-align: center;
    }
  }
}

.subTitle {
  margin-left: 50px;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: $white100fontColor;

  @include change-zoom {
    font-size: 26px;
    line-height: 32px;
  }

  @include not-a-desktop {
    text-align: left;
    margin: 0 auto;
    padding-bottom: 45px;
  }

  @include for-tablet-up {
    font-size: 24px;
    line-height: 28px;
  }

  @include for-big-phone-only {
    display: none;
  }
}
