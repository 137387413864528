@import '../../../../../../static/scss/global/variables';

.card {
  display: flex;
  background: $white100fontColor;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  transition: 0.4s;
  height: 209px;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
  }
}

.image {
  flex: 0 0 275px;
  background-position: center;
  background-size: cover;
  border-radius: 8px 0 0 8px;
  display: block;
  position: relative;
}

.infoWrapper {
  padding: 24px;
  flex-grow: 1;
}

.info {
  padding: 24px 35px 20px 24px;
  flex-grow: 1;

  & .link {
    margin-bottom: 20px;
    display: inline-block;
  }
}

.title {
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
}

.shoppingManagement {
  flex: 0 0 195px;
  padding-top: 16px;
}

.buttonsPosition {
  display: grid;
  grid-template-columns: 170px;
  grid-row-gap: 16px;
  margin-bottom: 30px;
}

.modelsOfActionPosition {
  position: absolute;
  top: 16px;
  right: 16px;
  opacity: 0;
  transition: opacity 0.4s;

  .card:hover & {
    opacity: 1;
  }

  &.modelsOfActionActive {
    opacity: 1;
  }
}

.price {
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
  background-color: $lightSectionColor;
}

.positioningStyleAuctionDate {
  justify-self: center;
  font-weight: 500;
  position: relative;
  top: 20px;
  display: flex;
  align-items: center;
}

.CalIcon {
  display: block;
  position: relative;
  left: -6px;
  top: -2px;
  transition: 0.3s;

  .positioningStyleAuctionDate:hover & {
    fill: $themeColor;
  }
}

.auctionDateValue {
  transition: 0.3s;

  *:hover > & {
    color: $themeColor;
  }
}
