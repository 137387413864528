@import '../../../static/scss/global/variables';

.wrapperComponent {
  color: $white100fontColor;
  opacity: 0.8;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}

.icon {
  fill: #ffffff;
  width: 14px;
  height: 14px;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: black;
  opacity: 0.6;
  cursor: pointer;
}
