@import '../../../static/scss/global/variables';

.header {
  text-transform: uppercase;
  text-align: center;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;

  @include change-zoom {
    font-size: 28px;
    line-height: 0;
  }

  @include for-big-phone-only {
    font-size: 20px;
    line-height: 30px;
    //padding: 0 10px;
  }

  i {
    font-style: normal;

    strong {
      font-weight: 700;
      color: $themeColor;
    }
  }

  a:hover {
    opacity: 0.8;
  }
}

.light {
  color: $white100fontColor;
}
