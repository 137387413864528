@import '../../../../../../static/scss/global/variables';

.shareLinkWrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.titlePopup {
  border-bottom: 1px solid #ededed;
  padding-bottom: 25px;
  margin-bottom: 30px;
}

.shareLinkItem {
  min-width: 103px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shareLinkItemFirstLine {
  margin-bottom: 30px;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #2a323c;
  border-radius: 50%;
  margin-bottom: 5px;
  transition: all 0.3s ease-out;

  .shareLinkItem:hover & {
    background: #eca52c;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
  }

  .icon {
    fill: white;
  }
}

.iconFacebook {
  margin-bottom: -12px;
}

.title {
  transition: all 0.3s ease-out;
  .shareLinkItem:hover & {
    color: #eca52c;
  }
}
