@import '../../../../../../static/scss/global/variables';

.wrapperComponent {
  position: relative;
  display: inline-block;
}

.shippingIcon {
  position: relative;
  top: 2px;
  left: 6px;
  opacity: 0.6;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
}

.itemWrapper {
  &:hover + .itemPrompt {
    display: block;
    transform: scale(1);
  }
}

.itemPrompt {
  display: none;
  transform: scale(0);
  position: absolute;
  bottom: 110%;
}
