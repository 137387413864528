@import '../../../static/scss/global/variables';

.wrapperComponent {
  padding: 10px 8px;
  color: $white100fontColor;
  opacity: 0.8;
  text-transform: uppercase;
  font-size: 14px;

  &:hover {
    opacity: 1;
  }

  &.active {
    color: $themeColor;
    opacity: 1;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 2px;
      bottom: 0;
      left: 3px;
      right: 3px;
      background-color: $themeColor;
      transform: skew(-45deg);
    }
  }
}
