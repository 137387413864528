@import '../../../static/scss/global/variables';

.priceInputWrapper {
  // height: 100%;
  position: relative;
}

.popover {
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid $borderBaseColor;
  padding: 24px;
  position: absolute;
  left: 0;
  right: 0;
  background: $white100fontColor;
  z-index: 2;
}

.containerInput {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  margin-bottom: 10px;
}

.containerApply {
  display: flex;
  justify-content: flex-end;
}

.apply {
  width: 48px;
  font-family: $headerFont;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $themeColor;
  cursor: pointer;
  user-select: none;
}

.applyHasError {
  cursor: not-allowed;
  opacity: 0.6;
}

.clear {
  width: 48px;
  font-family: $headerFont;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $themeColor;
  opacity: 0.6;
  margin-right: 20px;
  cursor: pointer;
  transition: opacity 0.2s;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }
}
