@import '../../../static/scss/global/variables';

.section {
  min-height: 300px;
  background: $darkSectionColor;
  padding: 56px 0;

  @include change-zoom {
    padding: 30px 0;
  }

  & .sectionWrapper {
    margin: 0 auto;
  }
}

.button {
  text-align: center;
  margin-top: 35px;

  @include not-a-desktop {
    margin-top: 20px;
  }
}

.carousel {
  max-width: 1100px;
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  @media (min-width: 1300px) {
    min-width: calc(100% - 100px);
  }
}

.blockItems {
  a {
    margin: 0 16px;
  }
}

.carouselWrapper {
  margin: 0 35px;

  @media (max-width: 400px) {
    margin: 0;
  }
}

.sc-bdVaJa .imYUvI {
  padding: 0;
}

.controlArrow {
  opacity: 0.5;
  color: #fff;
  width: 24px;
  height: 37px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='37' viewBox='0 0 24 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.334 18.666L4.8 37L0 32.246L13.726 18.666L0 5.086L4.8 0.332001L23.334 18.666Z' fill='white'/%3E%3C/svg%3E%0A");
  transition: opacity 0.15s ease-in;

  &:hover,
  &:focus {
    opacity: 1;
  }

  &.disabled {
    display: none !important;
  }
}

.controlPrev {
  transform: rotate(180deg);
}

.sectionHeaderDynamic {
  margin-bottom: 35px;

  @include change-zoom {
    margin-bottom: 20px;
  }
}
