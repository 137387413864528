@import '../../../../../../static/scss/global/variables';

.style {
  padding-bottom: 77px;
}

.resetButton {
  font-family: $secondaryFont;
  color: $darkSectionColorOp080;
  font-weight: 600;
  cursor: pointer;
}
