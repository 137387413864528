@import '../../../../../../static/scss/global/variables';

.wrapper {
  position: relative;
  height: 40px;
  z-index: 10;
}

.item {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  transform: translateX(-50%);
  z-index: 10;
}

.icon {
  margin-bottom: 7px;
}

.mileage {
  left: 25px;
}

.date {
  left: 212px;
}

.rate {
  left: 116px;
}

.value {
  font-size: 14px;
}

.dateIcon {
  transition: 0.3s;

  .date:hover & {
    fill: $themeColor;
  }
}

.dateValue {
  transition: 0.3s;

  .date:hover & {
    color: $themeColor;
  }
}

.conditionIcon {
  transition: 0.3s;

  .rate:hover & {
    fill: $themeColor;
  }
}

.conditionValue {
  transition: 0.3s;

  *:hover > & {
    color: $themeColor;
  }
}

.itemWrapper {
  &:hover + .itemPrompt {
    display: block;
    transform: scale(1);
  }
}

.itemPrompt {
  display: none;
  transform: scale(0);
  position: absolute;
  bottom: 110%;
}

.auctionDateWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
