@import '../../../../../../static/scss/global/variables';

.title {
  background-color: $darkestSectionColor;
  display: flex;
  align-items: center;
  min-height: 68px;
  padding: 0 24px;
  color: $white100fontColor;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  border-left: 4px solid $themeColor;

  @include for-big-phone-only {
    font-size: 16px;
    line-height: 24px;
  }
}

.content {
  border: 1px solid $borderBaseColor;
}
