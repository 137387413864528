@import '../../../static/scss/global/variables';

.wrapperSocial {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
  margin-bottom: 24px;
  justify-content: center;
}

.socialIcon {
  cursor: pointer;
}
.title {
  font-family: $headerFont;
  font-weight: bold;
  font-size: 34px;
  line-height: 51px;
  text-align: center;
  margin-bottom: 20px;
}

.useMailText {
  color: #000000;
  margin-bottom: 25px;
  text-align: center;
}

.forgotPassText {
  font-size: 14px;
  line-height: 16px;
  opacity: 0.8;
  text-align: center;
  margin-bottom: 24px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.changeLoginOption {
  font-family: $secondaryFont;
  font-size: 14px;
  line-height: 19px;
}

.changeLoginOptionLink {
  margin-left: 4px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.gridSignUp {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  margin-bottom: 24px;
}
.wrapperSignUpFromSection {
  padding-bottom: 24px;
  margin-bottom: 16px;
  border-bottom: 1px solid $borderBaseColor;
}

.wrapperTermsList {
  margin-bottom: 10px;
}

.termsTitle {
  font-style: italic;
  font-family: $secondaryFont;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
}

.term {
  font-style: italic;
  font-family: $secondaryFont;
  font-size: 12px;
  line-height: 16px;
}

.note {
  font-family: $secondaryFont;
  font-style: italic;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
}

.titleSmall {
  font-family: $headerFont;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 24px;
}

.bottomButton {
  position: absolute;
  bottom: 0;
  width: calc(100% - 24px);
}
