@import '../../../static/scss/global/variables';

.section {
  min-height: 200px;
  padding: 56px 0;
  background-color: $lightSectionColor;

  @include change-zoom {
    padding: 30px 0;
  }
}

.grid {
  display: grid;
  grid-template-columns: 670px auto;
  grid-column-gap: 50px;
  margin: 0 auto;

  @include change-zoom {
    grid-template-columns: 670px auto;
  }

  @include not-a-desktop {
    grid-template-columns: repeat(auto-fit, minmax(280px, 670px));
    justify-content: center;
  }
}

.video {
  position: relative;
  align-self: center;
  padding-bottom: 56.45%;
  max-height: 315px;
  max-width: 670px;
  width: 100%;

  iframe {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @include not-a-desktop {
    margin-right: 0;
  }
}

.steps {
  padding-left: 0;
  order: 2;
  max-width: 450px;
  justify-self: center;

  @include not-a-desktop {
    margin-top: 40px;
  }
}

.goToFAQLink {
  display: block;
  width: 170px;
  height: 38px;
  margin: auto;
  text-align: center;
  text-transform: uppercase;
  font-family: $buttonFont;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  color: $white100fontColor;
  background-color: $themeColor;

  span {
    display: inline-block;
    padding-top: 12px;
  }

  &:hover {
    background-color: #ffb230;
  }

  @include for-big-phone-only {
    min-width: 288px;
  }
}

.button {
  text-align: center;
  margin-top: 40px;

  @include change-zoom {
    margin-top: 20px;
  }
}
