@import '../../../static/scss/global/variables';

.title {
  font-family: $headerFont;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 24px;
}

.body {
  color: #000000;
  margin-bottom: 38px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.img {
  margin-bottom: 15px;
  width: 424px;
  height: 459px;
  border: 1px solid #ededed;
  border-radius: 4px;
}
