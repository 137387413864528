@import '../../../../../../../../static/scss/global/variables';

.name {
  color: rgba($darkSectionColor, 0.4);
  margin-bottom: 12px;
  white-space: nowrap;

  &:after {
    content: ':';
  }
  @include not-a-desktop {
    padding-right: 5px;
  }
}

.value {
  font-weight: 500;
  word-wrap: break-word;
  display: inline-block;
  max-width: 100px;
  margin-bottom: 12px;
  @include not-a-desktop {
    max-width: 100%;
  }
}

.propertyWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include not-a-desktop {
    display: flex;
  }
}
