@import '../../../../../../static/scss/global/variables';
.searchOutcome {
  top: 40px;
}

.wrapper {
  display: grid;
  margin-bottom: 40px;
}

.grid {
  grid-template-columns: repeat(auto-fill, 270px);
  gap: 30px 30px;
  justify-content: center;

  @media (max-width: 350px) {
    grid-template-columns: 270px;
  }
}

.list {
  gap: 15px 15px;
}

.pagination {
  display: flex;
  justify-content: center;
}
