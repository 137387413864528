@import '../../../../../../static/scss/global/variables';

.titlePopup {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 24px;
}

.chassis {
  margin-bottom: 16px;
}

.auctionSheet {
  margin: 0 auto 15px;
  width: 509px;
  height: 551px;
  border: 1px solid #ededed;
  border-radius: 4px;

  @media (max-width: 600px) {
    width: 440px;
    height: auto;
  }

  @media (max-width: 500px) {
    width: 322px;
    height: auto;
    margin: 0 auto 15px;
  }

  @media (max-width: 400px) {
    width: 250px;
    height: auto;
  }
}

.auctionSheetLink {
  display: block;
  width: 245px;
  text-align: center;
  text-transform: uppercase;
  font-family: $buttonFont;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  color: $white100fontColor;
  background-color: $themeColor;

  span {
    display: inline-block;
    padding-top: 15px;
  }

  &:hover {
    background-color: #ffb230;
  }

  @include for-big-phone-only {
    min-width: 310px;
    margin-bottom: 20px;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;

  .button {
    width: 245px;

    @include for-big-phone-only {
      min-width: 310px;
    }
  }

  @include for-big-phone-only {
    flex-direction: column;
    align-items: center;
  }
}

.popupHeader {
  display: flex;
  justify-content: space-between;
}

.popupCloseIcon {
  align-self: baseline;
}
