@import '../../../static/scss/global/variables';

.wrappedContainer {
  display: flex;
  align-items: center;
  max-width: 225px;

  .image {
    background-position: center;
    background-size: cover;
    width: 70px;
    height: 50px;
  }

  .wrapperDateTitle {
    padding-left: 8px;
  }
  .linkTitle {
    overflow: hidden;
    width: 155px;
    max-height: 33px;

    .title {
      font-family: $headerFont;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .date {
    opacity: 0.4;
    font-size: 12px;
    line-height: 14px;
    font-family: $headerFont;
    margin-top: 8px;
  }
}
