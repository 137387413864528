@import '../../../../static/scss/global/variables';

.component {
  display: grid;
  grid-template-columns: 90px auto;

  @include for-big-phone-only {
    grid-template-columns: 60px 195px;
  }

  &:last-of-type .contentHolder {
    border-left: none;
  }
}
.iconHolder {
  width: 46px;
  height: 46px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;

  @include change-zoom {
    width: 40px;
    height: 40px;
  }

  @include for-big-phone-only {
    width: 40px;
    height: 40px;
  }
}

.contentHolder {
  padding-left: 45px;
  border-left: 1px solid $darkSectionColor;
  position: relative;
  min-width: 200px;
  @include for-big-phone-only {
    padding-left: 20px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 13px;
    height: 13px;
    transform: translateX(-50%);
    box-shadow: 0 2px 0 0 $lightSectionColor, 0 -2px 0 0 $lightSectionColor;
    background: $darkSectionColor;
    left: 0;
    top: 2px;
  }
}

.num {
  font-weight: 600;
  text-transform: uppercase;
  color: $darkSectionColorOp080;
  margin-bottom: 5px;
  font-family: $headerFont;

  @include change-zoom {
    margin-bottom: 0;
  }

  @include for-big-phone-only {
    font-size: 14px;
    line-height: 21px;
  }
}
.title {
  font-family: $headerFont;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  //margin-bottom: 6px;
  text-transform: capitalize;

  @include change-zoom {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  @include for-big-phone-only {
    font-size: 16px;
    line-height: 24px;
  }
}
.description {
  margin-bottom: 15px;

  //@include change-zoom {
  //  margin-bottom: 20px;
  //}

  @include for-big-phone-only {
    font-size: 14px;
    line-height: 16px;
  }
}
