@import '../../../static/scss/global/variables';

%bodyFont {
  font-family: $mainFont;
  color: $darkSectionColor;

  font-size: 16px;
  line-height: 19px;
  @include change-zoom {
    font-size: 14px;
    line-height: 17px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headerFont;

  & span {
    color: $themeColor;
  }
}

h1 {
  color: $darkSectionColor;
  font-size: 34px;
  line-height: 51px;
  font-weight: 700;
  @include change-zoom {
    font-size: 28px;
    line-height: 42px;
  }
}
