@import '../../../static/scss/global/variables';

.wrapperComponent {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.1s;

  &:hover {
    background-color: $borderBaseColor;
  }

  &:before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $darkSectionColor;
    opacity: 0.4;
    position: absolute;
    top: 12px;
  }

  &:after {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $darkSectionColor;
    opacity: 0.4;
    position: absolute;
    top: 24px;
  }
}

.circle {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: $darkSectionColor;
  opacity: 0.4;
}
