@import '../../../static/scss/global/variables';

.popup {
  min-width: 400px;
  @media (max-width: 450px) {
    min-width: 200px;
  }
}

.addButton {
  width: 44px;
  height: 44px;
}

.terms {
  font-style: italic;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 32px;
  color: #d5322a;
}

.fieldRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
}

.button {
  z-index: 2;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: auto;
  width: 50px;
  height: 30px;
  background-position: center;
  align-self: center;
}

.field {
  margin-top: 2.5px;
  height: 37.5px;
  border: 1px solid $borderBaseColor;
  border-radius: 4px;
  padding: 0 34px 0 8px;
  display: block;
  width: 80%;
  caret-color: $themeColor;
  transition: 0.2s;

  &:focus {
    border: 1px solid $themeColor;
  }
}


