@import '../../../static/scss/global/variables';

.list {
  display: none;
  max-height: 310px;
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid $borderBaseColor;
  padding: 6px 0;
  position: absolute;
  left: 0;
  right: 0;
  background: $white100fontColor;
  z-index: 2;
}

.activated {
  display: block;
}

.listItem {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: #000000;
  transition: all 0.1s;
  cursor: pointer;

  &:hover {
    background: $themeColor;
    color: $white100fontColor;
  }
}

.select {
  font-weight: 300;
  background: $lightSectionColor;
}

.noMatch {
  text-align: center;
  padding: 15px 0;
}
